import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          menu: {
            home: "Home",
            boats: "Boats",
          },
          product: {
            viewDetails: "View Details",
            priceFrom: "from ",
          },

          reservation: {
            bookingMessage: "Booking Request from Bodrum.boats",
            makeareservation: "Make a Reservation",
            startDate: "Start Date",
            endDate: "End Date",
            tourDate: "Tour Date",
            tourName: "Tour Name",
            tourType: "Tour Type",
            tourPrice: "Tour Price",
            adults: "Adults",
            children: "Children",
            guests: "Guests",
            bookNow: "Book Now via Whatsapp!",
            ischarter: "Charter?",
            person: "Person",
            addBasket: "Add to Basket",
            pleaseSelect: "Please Select",

            payWhatsapp: "Complete order by Whatsapp",
            payDeposit: "Pay Deposit via Credit Card",
            payAll: "Pay via Credit Card",
            deposit: "Deposit",
          },
          amenities: {
            boatDetails: "Boat Details",
            boatType: "Boat Type",
            lenght: "Lenght",
            numberofCabins: "Number of Cabins",
            engine: "Engine",
          },
          pictures: {
            viewPhotos: "View Photos",
          },
          topBar: {
            overview: "Overview",
            details: "Details",
            reservation: "Reservation",
            boatDetails: "Boat Details",
            boatPrices: "Boat Prices",
            contactUs: "Contact Us",
            share: "Share",
          },
          tour: {
            prices: "Prices",
          },
          checkout: {
            cardHolder: "Card Holder",
            cardNumber: "Card Number",
            expires: "Expires",
            month: " Month",
            year: "Year",
            fullName: "NAME SURNAME",
            tourInformation: "Tour Information",
            basicInformation: "Basic Information",
            billingInformation: "Billing Information",
            paymentInformation: "Payment Information",
            identity: "Identity Number",
            date: "Tour Date",
            name: "Name",
            surname: "Surname",
            contactName: "Name and Surname",
            email: "Email",
            address: "Address",
            country: "Country",
            city: "City",
            state: "State",
            zipCode: "Zip Code",
            pHcontactName: "Please enter your Name and Surname",
            pHname: "Please enter your Name ",
            pHdate: "Please select tour date ",
            pHsurname: "Please enter your Surname",
            pHemail: "Please enter email address",
            pHaddress: "Please enter your Address",
            pHcountry: "Please enter your Country",
            pHcity: "Please enter your City",
            pHzipCode: "Please enter your Zip Code",
            pHstate: "Please enter your State",
            pHIdentity: "Please enter your Identity Number",
            checkIdentity: "Please check your Identity Number",
            checkname: "Please check your Name",
            checkcontactName: "Please check your Name and Surname",
            checkaddress: "Please check your Address",
            checkcountry: "Please check your Country",
            checkcity: "Please check your City",
            checkstate: "Please check your State",
            saveButton: "Save Address and Continue",
            addacard: "Add a Card",

            checkCreditCard: "Please check your credit card number",
            checkExpiricy: "Please check your expiricy date",
            checkCvc: "Please check your cvc",
            checkName: "Please check your name and surname",
            checkEmail: "Please check your email address",
            checkZipCode: "Please check your Zipcode",
            continue: "Add Card and Continue",
            placeOrder: "Place Order",
            paymentMethod: "Payment Method",
            orderPlaced: "Order Placed",
            orderPlacedHeader: "Congratulations!",
            orderPlacedDescription: "Your order has been placed successfully.",
            next: "Continue",
            back: "Back",
            pleaseSelectService: "Please Select Your Service",
            orderSummary: "Order Summary",
            pay: "Pay",
            orderReceived: "Your Order has been placed",
            orderStatus: "Order Status",
            error: "Error",
          },
        },
      },
      tr: {
        translation: {
          product: {
            viewDetails: "Detayları Gör",
            priceFrom: "Başlangıç fiyatı: ",
          },
          reservation: {
            bookingMessage: "Bodrum.boats'tan Rezervasyon Talebi",
            makeareservation: "Rezervasyon",
            startDate: "Başlangıç ​​tarihi",
            endDate: "Bitiş tarihi",
            tourDate: "Tur Tarihi",
            tourName: "Tur Adı",
            tourType: "Tur Türü",
            adults: "Yetişkinler",
            children: "Çocuklar",
            guests: "Misafirler",
            bookNow: "Whatsapp ile Rezervasyon Yapın!",
            person: "Kişi",
            addBasket: "Sepete Ekleyin",
            pleaseSelect: "Lütfen Seçiniz",
            payWhatsapp: "Siparişi Whatsapp ile tamamla",
            payDeposit: "Kredi Kartı ile Depozito öde",
            payAll: "Kredi Kartı ile Tamamını öde",
            deposit: "Depozito",
          },
          amenities: {
            boatDetails: "Tekne Detayları",
            boatType: "Tekne Tipi",
            lenght: "Uzunluk",
            numberofCabins: "Kabin Sayısı",
            engine: "Motor Gücü",
          },
          pictures: {
            viewPhotos: "Fotoğrafları Gör",
          },
          topBar: {
            overview: "Genel",
            boatDetails: "Tekne Detayları",
            boatPrices: "Tekne Fiyatları",
            contactUs: "Bize Ulaşın",
            share: "Paylaş",
          },
          checkout: {
            cardHolder: "Kart Sahibi",
            cardNumber: "Kart Numarası",
            expires: "Son Kullanma",
            month: "Ay",
            year: "Yıl",
            fullName: "ADI SOYADI",
            tourInformation: "Tur Bilgileri",
            basicInformation: "Genel Bilgileri",
            billingInformation: "Fatura Bilgileri",
            paymentInformation: "Ödeme Bilgileri",
            identity: "Kimlik Numarası",
            date: "Tur Tarihi",
            name: "Adı",
            surname: "Soyadı",
            contactName: "Adı ve Soyadı",
            email: "Email",
            address: "Adres",
            country: "Ülke",
            city: "Şehir",
            state: "State",
            zipCode: "Posta Kodu",
            pHcontactName: "Lütfen Ad ve Soyadınızı girin",
            pHname: "Lütfen Adınızı Girin ",
            pHdate: "Lütfen Tur Tarihini Seçin ",
            pHsurname: "Lütfen Soyadınızı Girin",
            pHemail: "Lütfen Email Adresinizi Girin",
            pHaddress: "Lütfen Adresinizi Girin",
            pHcountry: "Lütfen Ülkenizi Girin",
            pHcity: "Lütfen Şehrinizi Girin",
            pHzipCode: "Lütfen Posta Kodumnuzu Girin",
            pHstate: "Lütfen State Girin",
            pHIdentity: "Lütfen Kimlik Numaranızı Girin",
            checkIdentity: "Lütfen Kimlik Numaranızı Kontrol Edin",
            checkname: "Lütfen Adınızı Kontrol Edin",
            checkcontactName: "Lütfen Ad ve Soyadınızı Kontrol Edin",
            checkaddress: "Lütfen Adresinizi Kontrol Edin",
            checkcountry: "Lütfen Ülkenizi Kontrol Edin",
            checkcity: "Lütfen Şehrinizi Kontrol Edin",
            checkstate: "Lütfen State Kontrol Edin",
            checkZipCode: "Lütfen Posta Kodunuzu Kontrol Edin",
            saveButton: "Adresi Kaydet ve Detam Et",
            addacard: "Kart Ekleyin",

            checkCreditCard: "Lütfen Kredi Kartı Numaranızı Kontrol Edin",
            checkExpiricy: "Lütfen Son Kullanma Tarihinizi Kontrol Edin",
            checkCvc: "Lütfen CVC Kontrol Edin",

            checkEmail: "Lütfen Email Adresinizi Kontrol Edin",
            continue: "Kartı Kaydet ve Detam Et",
            placeOrder: "Sipariş Oluştur",
            paymentMethod: "Ödeme Yöntemi",
            orderPlaced: "Sipariş Oluşturuldu",
            orderPlacedHeader: "Tebrikler!",
            orderPlacedDescription: "Siparişiniz Başarı ile Oluşturuldu.",
            next: "Devam et",
            back: "Geri",
            pleaseSelectService: "Lütfen Servisi Seçiniz",
            orderSummary: "Sipariş Özeti",
            pay: "Öde",
            orderReceived: "Siparişiniz Oluşturuldu",
            orderStatus: "sipariş Durumu",
            error: "Hata",
          },
          tour: {
            prices: "Fiyatlar",
          },
        },
      },
      fr: {
        translation: {
          menu: {
            home: "Maison",
            boats: "Bateaux",
          },
          product: {
            viewDetails: "Voir les détails",
            priceFrom: "depuis ",
          },
          reservation: {
            bookingMessage: "Demande de réservation de Bodrum.boats",
            makeareservation: "Faire une réservation",
            startDate: "Date de début",
            endDate: "Date de fin",
            tourDate: "Date de la tournée",
            tourName: "Nom de la tournée",
            tourType: "Type de visite",
            tourPrice: "Prix ​​du voyage",
            adults: "Adultes",
            children: "Enfants",
            guests: "Invités",
            bookNow: "Réservez maintenant via Whatsapp !",
            ischarter: "Charte?",
            person: "Personne",
            addBasket: "ajouter au panier",
            pleaseSelect: "Veuillez sélectionner",
            payWhatsapp: "Terminer la commande par Whatsapp",
            payDeposit: "Payer le dépôt par carte de crédit",
            payAll: "Payer par carte de crédit",
            deposit: "Dépôt",
          },
          amenities: {
            boatDetails: "Détails du bateau",
            boatType: "Type de bateau",
            lenght: "Longueur",
            numberofCabins: "Nombre de cabines",
            engine: "Moteur",
          },
          pictures: {
            viewPhotos: "Voir les photos",
          },
          topBar: {
            overview: "Aperçu",
            details: "Détails",
            reservation: "Réservation",
            boatDetails: "Détails du bateau",
            boatPrices: "Tarifs des bateaux",
            contactUs: "Contactez-nous",
            share: "Partager",
          },
          tour: {
            prices: "Des prix",
          },
          checkout: {
            cardHolder: "Titulaire de la carte",
            cardNumber: "Numéro de carte",
            expires: "Expire",
            month: " Mois",
            year: "Année",
            fullName: "PRÉNOM / NOM DE FAMILLE",
            tourInformation: "Informations sur la visite",
            basicInformation: "Informations de base",
            billingInformation: "Informations de facturation",
            paymentInformation: "Informations de paiement",
            identity: "Numéro d'identité",
            date: "Date de la tournée",
            name: "Nom",
            surname: "Nom de famille",
            contactName: "Nom et surnom",
            email: "E-mail",
            address: "Adresse",
            country: "Pays",
            city: "Ville",
            state: "État",
            zipCode: "Code postal",
            pHcontactName: "Veuillez entrer votre nom et prénom",
            pHname: "S'il vous plaît entrez votre nom ",
            pHdate: "Veuillez sélectionner la date de la visite ",
            pHsurname: "Veuillez entrer votre nom de famille",
            pHemail: "Veuillez entrer l'adresse e-mail",
            pHaddress: "Veuillez entrer votre adresse",
            pHcountry: "Veuillez entrer votre pays",
            pHcity: "Veuillez entrer votre ville",
            pHzipCode: "S'il vous plait, entrer votre code postal",
            pHstate: "Veuillez entrer votre État",
            pHIdentity: "Veuillez saisir votre numéro d'identité",
            checkIdentity: "Veuillez vérifier votre numéro d'identité",
            checkname: "Veuillez vérifier votre nom",
            checkcontactName: "Veuillez vérifier votre nom et prénom",
            checkaddress: "Veuillez vérifier votre adresse",
            checkcountry: "Veuillez vérifier votre pays",
            checkcity: "Veuillez vérifier votre ville",
            checkstate: "Veuillez vérifier votre état",
            saveButton: "Enregistrer l'adresse et continuer",
            addacard: "Ajouter une carte",
            checkCreditCard:
              "Veuillez vérifier votre numéro de carte de crédit",
            checkExpiricy: "Veuillez vérifier votre date d'expiration",
            checkCvc: "Merci de vérifier votre CV",
            checkName: "Veuillez vérifier votre nom et prénom",
            checkEmail: "Veuillez vérifier votre adresse e-mail",
            checkZipCode: "Veuillez vérifier votre code postal",
            continue: "Ajouter une carte et continuer",
            placeOrder: "Passer la commande",
            paymentMethod: "Mode de paiement",
            orderPlaced: "Commande passée",
            orderPlacedHeader: "Toutes nos félicitations!",
            orderPlacedDescription: "Votre commande a été passée avec succès.",
            next: "Continuer",
            back: "Dos",
            pleaseSelectService: "Veuillez sélectionner votre service",
            orderSummary: "Récapitulatif de la commande",
            pay: "Payer",
            orderReceived: "Votre commande a bien été reçue",
            orderStatus: "Statut de la commande",
            error: "Erreur",
          },
        },
      },
      ru: {
        translation: {
          menu: {
            home: "Дом",
            boats: "Лодки",
          },
          product: {
            viewDetails: "Посмотреть детали",
            priceFrom: "от ",
          },
          reservation: {
            bookingMessage: "Запрос на бронирование от Bodrum.boats",
            makeareservation: "Зарезервировать",
            startDate: "Дата начала",
            endDate: "Дата окончания",
            tourDate: "Дата тура",
            tourName: "Название тура",
            tourType: "Тип тура",
            tourPrice: "Стоимость тура",
            adults: "Взрослые",
            children: "Дети",
            guests: "Гости",
            bookNow: "Забронируйте сейчас через WhatsApp!",
            ischarter: "Устав?",
            person: "Человек",
            addBasket: "Добавить в корзину",
            pleaseSelect: "Пожалуйста выберите",
            payWhatsapp: "Полный заказ по WhatsApp",
            payDeposit: "Оплатить депозит с помощью кредитной карты",
            payAll: "Оплата через кредитную карту",
            deposit: "Депозит",
          },
          amenities: {
            boatDetails: "Детали лодки",
            boatType: "Тип лодки",
            lenght: "Длина",
            numberofCabins: "Количество кают",
            engine: "Двигатель",
          },
          pictures: {
            viewPhotos: "Посмотреть фотографии",
          },
          topBar: {
            overview: "Обзор",
            details: "Подробности",
            reservation: "Бронирование",
            boatDetails: "Детали лодки",
            boatPrices: "Цены на лодки",
            contactUs: "Связаться с нами",
            share: "Делиться",
          },
          tour: {
            prices: "Цены",
          },
          checkout: {
            cardHolder: "Держатель карты",
            cardNumber: "Номер карты",
            expires: "Срок действия истекает",
            month: " Месяц",
            year: "Год",
            fullName: "ИМЯ ФАМИЛИЯ",
            tourInformation: "Информация о туре",
            basicInformation: "Основная информация",
            billingInformation: "Платежная информация",
            paymentInformation: "Платежная информация",
            identity: "Идентификационный номер",
            date: "Дата тура",
            name: "Имя",
            surname: "Фамилия",
            contactName: "Имя и фамилия",
            email: "Электронная почта",
            address: "Адрес",
            country: "Страна",
            city: "Город",
            state: "Состояние",
            zipCode: "Почтовый индекс",
            pHcontactName: "Пожалуйста, введите ваше имя и фамилию",
            pHname: "Пожалуйста, введите Ваше имя ",
            pHdate: "Пожалуйста, выберите дату тура ",
            pHsurname: "Пожалуйста, введите вашу фамилию",
            pHemail: "Пожалуйста, введите адрес электронной почты",
            pHaddress: "Пожалуйста, введите свой адрес",
            pHcountry: "Пожалуйста, введите вашу страну",
            pHcity: "Пожалуйста, введите ваш город",
            pHzipCode: "Пожалуйста, введите ваш почтовый индекс",
            pHstate: "Пожалуйста, укажите ваш штат",
            pHIdentity: "Пожалуйста, введите свой идентификационный номер",
            checkIdentity: "Пожалуйста, проверьте свой идентификационный номер",
            checkname: "Пожалуйста, проверьте свое имя",
            checkcontactName: "Пожалуйста, проверьте свое имя и фамилию",
            checkaddress: "Пожалуйста, проверьте свой адрес",
            checkcountry: "Пожалуйста, проверьте вашу страну",
            checkcity: "Пожалуйста, проверьте свой город",
            checkstate: "Пожалуйста, проверьте свой штат",
            saveButton: "Сохранить адрес и продолжить",
            addacard: "Добавить карту",
            checkCreditCard:
              "Пожалуйста, проверьте номер вашей кредитной карты",
            checkExpiricy: "Пожалуйста, проверьте срок годности",
            checkCvc: "Пожалуйста, проверьте свой CVC",
            checkName: "Пожалуйста, проверьте свое имя и фамилию",
            checkEmail: "Пожалуйста, проверьте свой адрес электронной почты",
            checkZipCode: "Пожалуйста, проверьте свой почтовый индекс",
            continue: "Добавить карту и продолжить",
            placeOrder: "Разместить заказ",
            paymentMethod: "Способ оплаты",
            orderPlaced: "Заказ размещен",
            orderPlacedHeader: "Поздравляем!",
            orderPlacedDescription: "Ваш заказ успешно размещен.",
            next: "Продолжать",
            back: "Назад",
            pleaseSelectService: "Пожалуйста, выберите вашу услугу",
            orderSummary: "итог заказа",
            pay: "Платить",
            orderReceived: "Ваш заказ был размещен",
            orderStatus: "Статус заказа",
            error: "Ошибка",
          },
        },
      },
      ar: {
        translation: {
          menu: {
            home: "بيت",
            boats: "قوارب",
          },
          product: {
            viewDetails: "عرض التفاصيل",
            priceFrom: "من ",
          },
          reservation: {
            bookingMessage: "طلب الحجز من Bodrum.boats",
            makeareservation: "احجز",
            startDate: "تاريخ البدء",
            endDate: "تاريخ الانتهاء",
            tourDate: "تاريخ الجولة",
            tourName: "اسم الجولة",
            tourType: "نوع الجولة",
            tourPrice: "سعر الجولة",
            adults: "الكبار",
            children: "أطفال",
            guests: "ضيوف",
            bookNow: "احجز الآن عبر الواتس اب!",
            ischarter: "الميثاق؟",
            person: "شخص",
            addBasket: "اضف الى السلة",
            pleaseSelect: "الرجاء التحديد",
            payWhatsapp: "استكمال الطلب عن طريق الواتس اب",
            payDeposit: "دفع الوديعة عن طريق بطاقة الائتمان",
            payAll: "الدفع عن طريق بطاقة الائتمان",
            deposit: "إيداع",
          },
          amenities: {
            boatDetails: "تفاصيل القارب",
            boatType: "نوع القارب",
            lenght: "الطول",
            numberofCabins: "عدد الكبائن",
            engine: "محرك",
          },
          pictures: {
            viewPhotos: "عرض الصور",
          },
          topBar: {
            overview: "ملخص",
            details: "تفاصيل",
            reservation: "حجز",
            boatDetails: "تفاصيل القارب",
            boatPrices: "أسعار القوارب",
            contactUs: "اتصل بنا",
            share: "يشارك",
          },
          tour: {
            prices: "الأسعار",
          },
          checkout: {
            cardHolder: "حامل البطاقة",
            cardNumber: "رقم البطاقة",
            expires: "تنتهي",
            month: " شهر",
            year: "سنة",
            fullName: "اسم اللقب",
            tourInformation: "معلومات الجولة",
            basicInformation: "معلومات اساسية",
            billingInformation: "معلومات الفواتير",
            paymentInformation: "معلومات الدفع",
            identity: "رقم الهوية",
            date: "تاريخ الجولة",
            name: "اسم",
            surname: "اسم العائلة",
            contactName: "اسم ولقب",
            email: "بريد إلكتروني",
            address: "عنوان",
            country: "دولة",
            city: "مدينة",
            state: "ولاية",
            zipCode: "الرمز البريدي",
            pHcontactName: "الرجاء إدخال اسمك ولقبك",
            pHname: "من فضلك أدخل إسمك ",
            pHdate: "الرجاء تحديد تاريخ الجولة ",
            pHsurname: "الرجاء إدخال اللقب الخاص بك",
            pHemail: "الرجاء إدخال عنوان البريد الإلكتروني",
            pHaddress: "الرجاء إدخال عنوانك",
            pHcountry: "الرجاء إدخال بلدك",
            pHcity: "الرجاء إدخال مدينتك",
            pHzipCode: "الرجاء إدخال الرمز البريدي الخاص بك",
            pHstate: "الرجاء إدخال ولايتك",
            pHIdentity: "الرجاء إدخال رقم الهوية الخاص بك",
            checkIdentity: "يرجى التحقق من رقم الهوية الخاصة بك",
            checkname: "يرجى التحقق من اسمك",
            checkcontactName: "يرجى التحقق من اسمك ولقبك",
            checkaddress: "يرجى التحقق من عنوانك",
            checkcountry: "يرجى التحقق من بلدك",
            checkcity: "يرجى التحقق من مدينتك",
            checkstate: "يرجى التحقق من ولايتك",
            saveButton: "احفظ العنوان وتابع",
            addacard: "إضافة بطاقة",
            checkCreditCard: "يرجى التحقق من رقم بطاقة الائتمان الخاصة بك",
            checkExpiricy: "يرجى التحقق من تاريخ انتهاء الصلاحية الخاص بك",
            checkCvc: "يرجى التحقق من cvc الخاص بك",
            checkName: "يرجى التحقق من اسمك ولقبك",
            checkEmail: "يرجى التحقق من عنوان البريد الإلكتروني الخاص بك",
            checkZipCode: "يرجى التحقق من الرمز البريدي الخاص بك",
            continue: "أضف البطاقة وتابع",
            placeOrder: "مكان الامر",
            paymentMethod: "طريقة الدفع او السداد",
            orderPlaced: "تم الطلب",
            orderPlacedHeader: "تهانينا!",
            orderPlacedDescription: "لقد تم تقديم طلبك بنجاح.",
            next: "يكمل",
            back: "خلف",
            pleaseSelectService: "الرجاء تحديد الخدمة الخاصة بك",
            orderSummary: "ملخص الطلب",
            pay: "يدفع",
            orderReceived: "وقد وضعت طلبك",
            orderStatus: "حالة الطلب",
            error: "خطأ",
          },
        },
      },
    },
  });

export default i18n;
